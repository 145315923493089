import React from 'react';
import { Autocomplete } from '@mui/lab';
import { TextField } from '@mui/material';
import { RootState } from 'store/reducers';
import { connect, ConnectedProps } from 'react-redux';

type Node = {
  name: string;
  label?: string;
  resourceType: string;
  children?: Node[];
};

const mapState = (state: RootState) => ({
  siteList: state.resourcesReducer.siteList,
});

const connector = connect(mapState);

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  filterList: any[];
  index: number;
  column: any;
  onChange: (filterList: any[], index: number, column: any) => void;
};
type Props = ReduxProps & OwnProps;

const flattenSiteList = (siteList: Node[]): string[] => {
  const resourcePaths: string[] = [];

  const traverse = (node: Node, path = '') => {
    const currentPath = node.resourceType === 'SITE' ? `${path}/${node.label}` : `${path}/${node.name}`;
    resourcePaths.push(currentPath);

    if (node.children && node.children.length > 0) {
      node.children.forEach(child => traverse(child, currentPath));
    }
  };

  siteList.forEach(site => traverse(site));
  return resourcePaths;
};

const ResourcePathSelector = ({
  filterList,
  index,
  column,
  onChange,
  siteList,
} : Props )=> {
  const optionValues = flattenSiteList(siteList);
  return (
    <Autocomplete
      options={optionValues}
      getOptionLabel={(option) => option}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search Resource Path"
          variant='standard'
          value={filterList[index][0] || ''}
        />
      )}
      onChange={(event, value) => {
        //eslint-disable-next-line
        filterList[index][0] = value;
        onChange(filterList[index], index, column);
      }}
    />
  );
};

export default connector(ResourcePathSelector);
