import { ReactNode, Ref, createRef, useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import DatePickerIcon from '@mui/icons-material/CalendarToday';
import AddUsersIcon from '@mui/icons-material/GroupAdd';
import HelpIcon from '@mui/icons-material/HelpOutline';
import KeyBoardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyBoardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Input, InputLabel, NativeSelect, Tooltip } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import Loader from 'components/loader/loader';
import { MANAGER, USER } from 'constants/user-authority';
import { DateTime } from 'luxon';
import vmInstanceService from 'services/vm-instance-service';
import { useAppDispatch } from 'store';
import { addMachineTemplates } from 'store/actions/machine-template-actions';
import { addUser } from 'store/actions/manage-rights-actions';
import { RootState } from 'store/reducers';
import { getSiteAvailableMachineTemplatesFromState } from 'store/selectors/machine-template-selectors';
import { AvailableMachineTemplateDTO, ResourceAccessNode } from 'types';
import styles from './add-users.module.scss';
import UsersSelectorInput, { ValidateInput } from './users-selector-input';

type Resources = {
  site: ResourceAccessNode,
  zone?: ResourceAccessNode,
  project?: ResourceAccessNode
};

const mapState = (state: RootState) => ({
  siteList: state.resourcesReducer.siteList,
  getSiteAvailableMachineTemplatesFromState: (siteId) => getSiteAvailableMachineTemplatesFromState(state, siteId),
});

const connector = connect(mapState);

type ReduxProps = ConnectedProps<typeof connector>;

type OwnProps = {
  children: ReactNode | null;
  resourceData?: Resources;
  onClose?: () => void;
  callBack?: () => void;
  withMenuStyle: boolean;
};

type Props = ReduxProps & OwnProps;

type Selection = {
  selectedSite: ResourceAccessNode | undefined,
  selectedZone: ResourceAccessNode | undefined,
  selectedProject: ResourceAccessNode | undefined,
};

const AddUsers = ({
                    resourceData, siteList, onClose, callBack, getSiteAvailableMachineTemplatesFromState, children, withMenuStyle,
                  }: Props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userIdList, setUserIdList] = useState<string[]>([]);
  const [userIdInput, setUserIdInput] = useState(''); // Hack to add userId in input if validate clicked and chip is not in chip list
  const [status, setStatus] = useState(USER);
  const [machineTemplateId, setMachineTemplateId] = useState(0);
  const [availableMachineTemplates, setAvailableMachineTemplates] = useState<AvailableMachineTemplateDTO[]>([]);
  const [expirationDate, setExpirationDate] = useState(DateTime.local().plus({ months: 3 }));
  const [selection, setSelection] = useState<Selection>({
    selectedSite: resourceData ? resourceData.site || undefined : undefined,
    selectedZone: resourceData ? resourceData.zone || undefined : undefined,
    selectedProject: resourceData ? resourceData.project || undefined : undefined,
  });

  const disableSiteZoneProjectSelection = resourceData !== undefined;
  const usersSelectorInputRef: Ref<ValidateInput> = createRef();

  useEffect(() => {
    if (selection.selectedSite) {
      const siteId = selection.selectedSite.resourceId;
      const availableMachineTemplates = getSiteAvailableMachineTemplatesFromState(siteId);
      if (!availableMachineTemplates) {
        vmInstanceService.getMachineTemplates(siteId).then((availableMachineTemplates) => {
          const result = { [siteId]: availableMachineTemplates };
          updateMachineTemplates(availableMachineTemplates);
          dispatch(addMachineTemplates(result));
        });
      }
      updateMachineTemplates(availableMachineTemplates);
    }
  }, [selection.selectedSite]);

  const openModal = (event) => {
    setOpen(true);
    event.stopPropagation();
  };

  const handleClose = (event?) => {
    if (event) {
      event.stopPropagation();
    }
    setOpen(false);
    setUserIdList([]);
    setSelection({ selectedSite: undefined, selectedZone: undefined, selectedProject: undefined });
    setExpirationDate(DateTime.local().plus({ months: 3 }));
    setStatus(USER);
    setMachineTemplateId(0);

    if (onClose) {
      onClose();
    }
  };

  const updateMachineTemplatesWithSiteId = (siteId: number) => {
    const templates = getSiteAvailableMachineTemplatesFromState(siteId);
    updateMachineTemplates(templates);
  };

  const updateMachineTemplates = (templates: AvailableMachineTemplateDTO[]) => {
    setAvailableMachineTemplates(templates);
    setMachineTemplateId(templates && templates.length > 0 ? templates[0].id : 0);
  };

  const handleSiteChange = (event) => {
    const siteId = Number(event.target.value);
    const selectedSite = siteList.find(site => site.resourceId === siteId);
    setSelection({
      selectedSite: selectedSite,
      selectedZone: undefined,
      selectedProject: undefined,
    });

    updateMachineTemplatesWithSiteId(selectedSite ? selectedSite.resourceId : 0); // Here we keep the 0 as siteId in case of undefined site
  };

  const handleZoneChange = (event) => {
    if (selection.selectedSite) {
      const zoneId = Number(event.target.value);
      const selectedZone = selection.selectedSite.children.find(zone => zone.resourceId === zoneId);
      setSelection({
        ...selection,
        selectedZone: selectedZone,
        selectedProject: undefined,
      });
    }
  };

  const handleProjectChange = (event) => {
    if (selection.selectedZone) {
      const projectId = Number(event.target.value);
      const selectedProject = selection.selectedZone.children.find(project => project.resourceId === projectId);
      setSelection({
        ...selection,
        selectedProject: selectedProject,
      });
    }
  };

  const handleStatusChange = (event) => {
    const { value } = event.target;
    setStatus(value);
  };

  const handleMachineTemplateChange = (event) => {
    const { value } = event.target;
    setMachineTemplateId(value);
  };

  const handleUserIdListChange = (inputFieldValue) => {
    setUserIdList(inputFieldValue);
  };

  const handleUserIdInputChange = (value) => {
    setUserIdInput(value);
  };

  const handleDateChange = (date) => {
    setExpirationDate(date);
  };

  const grantUserCallback = () => {
    setIsLoading(false);
    if (onClose) {
      onClose();
    }
    handleClose();
    if (callBack) {
      callBack();
    }
  };

  const createAccessControl = () => {
    // Validate current input in component before sendind request
    const isValidInput = usersSelectorInputRef.current?.validateInput();
    if (isValidInput && selection.selectedSite) {
      setIsLoading(true);
      const { selectedProject, selectedZone, selectedSite } = selection;
      let resourceType;
      if (selectedProject && selectedProject.resourceId) resourceType = 'PROJECT';
      else if (selectedZone && selectedZone.resourceId) resourceType = 'ZONE';
      else resourceType = 'SITE';

      const completeUserIdList = userIdList.map(applicant => applicant.toUpperCase());
      const userIdInputTrim = userIdInput ? userIdInput.trim() : undefined;
      if (userIdInputTrim && userIdInputTrim.length > 0) {
        completeUserIdList.push(userIdInputTrim.toUpperCase());
      }
      const createAccessControlRequestBody = {
        siteId: selectedSite.resourceId,
        zoneId: selectedZone ? selectedZone.resourceId : undefined,
        projectId: selectedProject ? selectedProject.resourceId : undefined,
        resourceType: resourceType,
        applicants: completeUserIdList,
        authority: status,
        machineTemplateId: machineTemplateId,
        expirationDate: DateTime.fromISO(expirationDate).toFormat('dd/LL/yyyy'),
      };
      dispatch(addUser(createAccessControlRequestBody)).finally(() => grantUserCallback());
    }
  };

  const getAuthorityErrorMessage = () => {
    const { selectedProject, selectedZone, selectedSite } = selection;

    if (selectedProject) {
      return '';
    }

    if (selectedZone) {
      if (selectedZone.authority !== MANAGER) {
        return `You do not have manager access on this storage: '${selectedZone.name}'`;
      }
    } else if (selectedSite && selectedSite.authority !== MANAGER) {
      return `You do not have manager access on this site: '${selectedSite.label}'`;
    }

    return '';
  };

  const disableButton = () => {
    return (userIdList.length === 0 && !userIdInput) || !selection.selectedSite || isLoading;
  };

  return (
    <div onClick={openModal} className={withMenuStyle ? styles.menu_item_content : styles.add_user_button}>
      {children}

      <Dialog open={open} maxWidth='lg' onClose={handleClose}>
        <DialogTitle className={styles.dialogTitle}>
          <AddUsersIcon className={styles.icon} />
          <div className={styles.addUsersTitle}>Add users</div>
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <Grid container spacing={1} alignItems='center' direction='row' justifyContent='center' className={styles.accessAttributionGrid}>
            <Grid item xs>
              <FormControl>
                <InputLabel shrink className={styles.inputFieldLabel}>
                  Site
                </InputLabel>
                <NativeSelect
                  className={styles.inputField}
                  value={selection.selectedSite ? selection.selectedSite.resourceId : 0}
                  onChange={handleSiteChange}
                  input={<Input name='site' />}
                  disabled={disableSiteZoneProjectSelection}>
                  {!selection.selectedSite && <option value='' />}
                  {siteList
                    .filter(site => site.authority === MANAGER || site.children.some(zone => zone.authority === MANAGER || zone.children.some(project => project.authority === MANAGER)))
                    .map(site => (
                      <option value={site.resourceId} key={site.resourceId}>
                        {site.label}
                      </option>
                    ))}
                </NativeSelect>
              </FormControl>
            </Grid>

            <Grid item xs>
              <FormControl>
                <InputLabel shrink className={styles.inputFieldLabel}>
                  Storage
                </InputLabel>
                <NativeSelect
                  className={styles.inputField}
                  value={selection.selectedZone ? selection.selectedZone.resourceId : 0}
                  onChange={handleZoneChange}
                  input={<Input name='zone' />}
                  disabled={disableSiteZoneProjectSelection || !selection.selectedSite} >
                  <option value={0}>{selection.selectedSite ? '*' : ''}</option>
                  {
                    selection.selectedSite
                    && selection.selectedSite.children
                    && selection.selectedSite.children
                      .filter(zone => zone.authority === MANAGER || zone.children.some(project => project.authority === MANAGER))
                      .map(zone => (
                        <option value={zone.resourceId} key={zone.resourceId}>
                          {zone.name}
                        </option>
                      ))}
                </NativeSelect>
              </FormControl>
            </Grid>

            <Grid item xs>
              <FormControl>
                <InputLabel shrink className={styles.inputFieldLabel}>
                  Project
                </InputLabel>
                <NativeSelect
                  className={styles.inputField}
                  value={selection.selectedProject ? selection.selectedProject.resourceId : 0}
                  onChange={handleProjectChange}
                  input={<Input name='project' />}
                  disabled={disableSiteZoneProjectSelection || !selection.selectedZone} >
                  <option value={0}>{selection.selectedSite ? '*' : ''}</option>
                  {
                    selection.selectedZone
                    && selection.selectedZone.children
                    && selection.selectedZone.children
                      .filter(project => project.authority === MANAGER)
                      .map(project => (
                        <option value={project.resourceId} key={project.resourceId}>
                          {project.name}
                        </option>
                      ))}
                </NativeSelect>
              </FormControl>
            </Grid>
          </Grid>

          <div className={styles.usersSelectorContainer}>
            <InputLabel shrink className={styles.largerInputFieldLabel}>
              <div className={styles.labelWithTooltip}>
                <span>User name</span>
                <Tooltip
                  classes={{
                    tooltip: styles.tooltip,
                  }}
                  title='Type a name to find a user. If they are not yet known in SaaS, use their IGG.' >
                  <HelpIcon />
                </Tooltip>
              </div>
            </InputLabel>
            <UsersSelectorInput ref={usersSelectorInputRef} iggOnly onChange={handleUserIdListChange} onInputChange={handleUserIdInputChange} />
          </div>

          <Grid container className={styles.accessAttributionGrid} spacing={1} alignItems='center' direction='row' justifyContent='center'>
            <Grid item xs>
              <FormControl>
                <InputLabel shrink className={styles.inputFieldLabel}>
                  Status
                </InputLabel>
                <NativeSelect className={styles.inputField} value={status} onChange={handleStatusChange} input={<Input name='status' />}>
                  <option value='USER'>User</option>
                  <option value='MANAGER' className={styles.inputField}>
                    Manager
                  </option>
                </NativeSelect>
              </FormControl>
            </Grid>
            <Grid item xs>
              <DatePicker
                slots={{
                  openPickerIcon: DatePickerIcon,
                  leftArrowIcon: KeyBoardArrowLeft,
                  rightArrowIcon: KeyBoardArrowRight,
                }}
                onChange={handleDateChange}
                value={expirationDate}
                minDate={DateTime.local().plus({ days: 1 })}
                maxDate={DateTime.local().plus({ months: process.env.REACT_APP_MAX_RIGHTS_EXPIRATION_TIME_MONTH ? +process.env.REACT_APP_MAX_RIGHTS_EXPIRATION_TIME_MONTH : 6 })}
                format='yyyy/MM/dd'
                slotProps={{
                  textField: {
                    label: 'End access date',
                    classes: { root: styles.inputFieldDate },
                    InputLabelProps: { shrink: true, classes: { root: styles.inputFieldDateLabel } },
                    sx: {
                      boxShadow: 'none',
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                      '.MuiOutlinedInput-input': { padding: '10px 0 10px' },
                    },
                  },
                }} />
            </Grid>
            <Grid item xs className={styles.machineCategory}>
              <FormControl>
                <InputLabel shrink className={styles.inputFieldLabel}>
                  <div className={styles.labelWithTooltip}>
                    <span>Machine category</span>
                    <Tooltip
                      classes={{
                        tooltip: styles.tooltip,
                      }}
                      title={
                        <>
                          {availableMachineTemplates &&
                            availableMachineTemplates.map((m) => (
                              <div>
                                {m.name}: {m.description}. Price = ${m.hourlyCost}/hour
                              </div>
                            ))}
                        </>
                      } >
                      <HelpIcon />
                    </Tooltip>
                  </div>
                </InputLabel>
                <NativeSelect className={styles.inputField} value={machineTemplateId} onChange={handleMachineTemplateChange} input={<Input name='machineCategory' />}>
                  {availableMachineTemplates &&
                    availableMachineTemplates.map((m) => (
                      <option value={m.id} key={m.id}>
                        {m.name.toUpperCase()}
                      </option>
                    ))}
                </NativeSelect>
                <InputLabel className={styles.tooltipContainer} />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container alignItems='stretch' direction='row' justifyContent='flex-end' className={styles.accessAttributionButtonGrid}>
            <Grid item>
              <Button onClick={handleClose} className={styles.cancelButton}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Tooltip title={getAuthorityErrorMessage()} disableHoverListener={!getAuthorityErrorMessage()}>
                <div>
                  <Button onClick={createAccessControl} variant='contained' color='primary' className={styles.validationButton} disabled={disableButton()}>
                    {isLoading ? <Loader className={styles.loadericon} /> : <>VALIDATE</>}
                  </Button>
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};


export default connector(AddUsers);
